export const SITE_URL = process.env.GATSBY_STORE_URL;
export const STOREFRONT_TOKEN = process.env.GATSBY_STOREFRONT_TOKEN;
export const SHOPIFY_HEADLESS_TOKEN = process.env.GATSBY_SHOPIFY_HEADLESS_TOKEN;
export const SHOPIFY_STOREFRONT_ID = process.env.GATSBY_SHOPIFY_STOREFRONT_ID;
export const ADMIN_TOKEN = process.env.GATSBY_ADMIN_TOKEN;
export const PMS_URL = process.env.GATSBY_PMS_URL;
export const SYNC_WITH_OLD_SITE = process.env.GATSBY_SYNC_WITH_OLD_SITE === "true";
export const localStorageKey = "sealskin-custom-access-token";
export const sessionStorageKey = "sealskin-customer";
export const checkoutKey = "checkoutId";
export const MAIL_ID_REGEX = /MailingAddress\/(\d+)/;
export const authErrors = {
    UNIDENTIFIED_CUSTOMER: "Incorrect email or password",
    TAKEN: "This email has already been taken",
    CUSTOMER_DISABLED: "This account has been disabled",
};
export const cleanAddressId = (addressId) => addressId.match(MAIL_ID_REGEX)[1];
export const TELEPHONE_NUMBER = "800-915-0038";
export const cartSync = "cartSync";
export const DOTS = "...";
export const priceCheckURL = `${PMS_URL}/products/price_check`;
export const discountProxyURL = "https://sbb0fqirt6.execute-api.eu-central-1.amazonaws.com/prod/discountcode";
export const checkoutProxyURL = `${PMS_URL}/checkouts`;
export const shopifyUploadURL = `${PMS_URL}/upload`;
export const CURRENCY = "USD";
