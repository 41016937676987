import { SYNC_WITH_OLD_SITE } from "utils/constants";
import { buildStableSyncCart } from "./checkout-build-function";

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return null;
}

export function getCartSessionFromCookie() {
    const fullCartSyncCookie = getCookie("cartSync");

    return JSON.parse(fullCartSyncCookie);
}

export function getCartSyncGUID() {
    if (getCookie("cartSync")) return JSON.parse(getCookie("cartSync")).gUID;
    return null;
}

export function setCartSyncCookie(gUID, checkout) {
    const stringifiedItems = JSON.stringify({ gUID, checkout });
    document.cookie = `cartSync=${stringifiedItems}; domain=.sealskincovers.com; path=/; secure=true; sameSite=Strict`;
}

export const decodedGID = (string) => {
    const splittedGID = string.split("/");
    return splittedGID[splittedGID.length - 1];
};

export const createOrUpdateCartCookie = async (cart) => {
    if (!SYNC_WITH_OLD_SITE) return null;
    const checkout = await buildStableSyncCart(cart);

    return fetch(`https://ssc-cart.sogody.co.uk/${getCartSyncGUID() !== null ? `?gUID=${getCartSyncGUID()}` : ""}`, {
        method: "POST",
        body: JSON.stringify({ checkout, fromHeadless: true }),
        headers: { "Content-Type": "application/json" },
    })
        .then((response) => response.json())
        .then((workerData) => setCartSyncCookie(workerData.gUID, workerData.checkout));
};

export const createOrUpdateCartAsLocal = async (cart) => {
    const checkout = await buildStableSyncCart(cart);
    const cartToEncode = { checkout, fromHeadless: true };

    const encodedCart = JSON.stringify(cartToEncode);
    localStorage.setItem("ssc-cart", encodedCart);
};

export const redirectFromControl = () => {
    const encodedCart = localStorage.getItem("ssc-cart");
    return JSON.parse(encodedCart);
};
