import {
    addLineItemQuery,
    applyDiscountCodeQuery,
    associateAddressWithCheckoutQueryV2,
    associateCustomerWithCheckoutQueryV2,
    createCheckoutQuery,
    getCheckoutQuery,
    removeDiscountCodeQuery,
    updateLineItemQuery,
} from "shopify-queries/storefront/cart";
import { withAccessToken } from "services/storefront/account";
import {
    checkoutKey, discountProxyURL, cartSync, PMS_URL,
} from "utils/constants";
import axios from "axios";
import storefrontQuery from "../common";

export const getCheckoutId = () => localStorage.getItem(checkoutKey);

export const getCartSyncItemFromLS = () => localStorage.getItem(cartSync);

export const createCheckout = (input) => storefrontQuery(createCheckoutQuery, { input });

export const getOrCreateCheckoutId = async (init = true, lineItems = []) => {
    const checkoutId = getCheckoutId();
    if ((checkoutId && !lineItems.length) && init) return checkoutId;

    localStorage.setItem("useHeadlessToken", true);
    // Create new checkout
    const newCheckout = await createCheckout({ lineItems });

    localStorage.setItem(checkoutKey, newCheckout.data.checkoutCreate.checkout.id);
    return newCheckout.data.checkoutCreate.checkout.id;
};

export const getCheckout = (checkoutId) => storefrontQuery(getCheckoutQuery, { checkoutId });

export const addLineItem = (checkoutId, lineItems, properties) => storefrontQuery(addLineItemQuery, {
    checkoutId, lineItems, properties, 
});

export const updateLineItem = (checkoutId, lineItems) => storefrontQuery(updateLineItemQuery, { checkoutId, lineItems });

export const applyDiscountCode = (checkoutId, discountCode) => storefrontQuery(applyDiscountCodeQuery, { checkoutId, discountCode });

export const removeDiscountCode = (checkoutId) => storefrontQuery(removeDiscountCodeQuery, { checkoutId });

export const associateCustomerWithCheckoutV2 = (checkoutId, customerAccessToken) => storefrontQuery(associateCustomerWithCheckoutQueryV2, { checkoutId, customerAccessToken });

export const associateAddressWithCheckoutV2 = (checkoutId, shippingAddress) => storefrontQuery(associateAddressWithCheckoutQueryV2, { checkoutId, shippingAddress });

export const checkoutLoggedInCustomer = async () => {
    await withAccessToken(async (token) => {
        const checkoutId = await getOrCreateCheckoutId();
        const customerAddress = await associateCustomerWithCheckoutV2(checkoutId, token.accessToken);
        const { defaultAddress } = customerAddress.data.checkoutCustomerAssociateV2.customer;
        await associateAddressWithCheckoutV2(checkoutId, defaultAddress);
    });
};

export const applyProxyDiscount = async (discountCode, totalOrderPrice) => {
    const discountData = await axios.get(`${discountProxyURL}?option=check&code=${discountCode}&totalOrderPrice=${totalOrderPrice}`);
    return discountData?.data || {};
};

export const applyProxyDiscountV2 = async (cart) => {
    try {
        const response = await axios.post(`${PMS_URL}/checkouts/apply_discount`, { ...cart });

        if (response.status === 200) return response.data;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error, "Hello");
    }
    return null;
};
