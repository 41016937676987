import { STOREFRONT_TOKEN, SITE_URL, SHOPIFY_HEADLESS_TOKEN, SHOPIFY_STOREFRONT_ID } from "utils/constants";
import { productFragment, collectionsFragment } from "shopify-queries/storefront/common";
import { getShopifyCookies } from "@shopify/hydrogen-react";

export const getStorefrontToken = () => {
    const shouldUseHeadlessToken = localStorage.getItem("useHeadlessToken");

    return shouldUseHeadlessToken === "true" ? SHOPIFY_HEADLESS_TOKEN : STOREFRONT_TOKEN;
};

const getStorefrontId = () => {
    const shouldUseHeadlessToken = localStorage.getItem("useHeadlessToken");

    return shouldUseHeadlessToken === "true" ? SHOPIFY_STOREFRONT_ID : undefined;
}

const storefrontQuery = async (query, variables) => {
    try {
        const shopifyCookies = getShopifyCookies(document.cookie);

        const response = await fetch(`${SITE_URL}/api/2024-01/graphql.json`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "X-Shopify-Storefront-Access-Token": getStorefrontToken(),
                "Shopify-Storefront-Y": shopifyCookies._shopify_y,
                "Shopify-Storefront-S": shopifyCookies._shopify_s,
                "Shopify-Storefront-Id": getStorefrontId()
            },
            body: JSON.stringify({
                query,
                variables,
            }),
        });
        const result = await response.json();
        return result;
    } catch (err) {
        return err;
    }
};

export const getProducts = async (handles) => {
    /* Dynamically creates sub-queries like:
        product1: product(handle: "x-y") {
            productInfo...
        }
        product2: product(handle: "x-y") {
            productInfo...
        }
    Since Shopify currently doesn't support fetching multiple products by their handles
    */

    const productsByHandles = handles.map((handle, index) => `
        product${index}: product(handle: "${handle}") {
            ${productFragment}
        }
    `);

    const fetchProducts = await storefrontQuery(`
        query {
            ${productsByHandles}
        }
    `);

    return Object.values(fetchProducts.data || {});
};

export const getMediaImage = async (gIds) => {
    const mediaImages = await storefrontQuery(`
        query {
            nodes(ids: ${JSON.stringify(gIds)}) { 
                id
                ... on MediaImage { 
                image {
                    id 
                    url
               }
            }
          }
        }`);

    return Object.values(mediaImages.data || {});
};

export const getCollections = async (handles) => {
    const collectionsByHandle = handles.map((handle, index) => `
        collection${index}: collectionByHandle(handle: "${handle}") {
            ${collectionsFragment}
        }
    `);

    const collections = await storefrontQuery(`
    query { 
        ${collectionsByHandle}
    }`);

    return collections;
};

export default storefrontQuery;
