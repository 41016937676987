import React, { createContext, useState } from "react";
import {
    addLineItem,
    applyDiscountCode,
    getCheckout,
    getOrCreateCheckoutId,
    removeDiscountCode,
    updateLineItem,
    applyProxyDiscountV2, 
} from "services/storefront/cart";
import axios from "axios";
import getStableCheckout from "helpers/checkout-build-function";
import { checkoutProxyURL, SYNC_WITH_OLD_SITE } from "utils/constants";
import { createOrUpdateCartCookie, getCartSessionFromCookie } from "helpers/cart-sync";
import checkWarrantyProducts from "@root/helpers/checkWarrantyProducts";

const defaultPrice = { currencyCode: "USD", amount: 0 };

const initialState = {
    items: [],
    error: "",
    checkoutUrl: "",
    discountCode: "",
    totalPrice: defaultPrice,
    subtotalPrice: defaultPrice,
    discountPrice: defaultPrice,
    createdAt: "",
    updatedAt: "",
    hasDiscount: "",
    isLoading: true,
};

const getAppliedDiscountCodeErrors = (discountCode) => {
    switch (discountCode) {
    case "DISCOUNT_NOT_FOUND": 
        return "Unable to find a valid discount matching the code entered";
    case "ALREADY_EXISTS": 
        return "Discount code has already been applied to the cart";
    case "EXPIRED": 
        return "Discount code has expired";
    case "HIGHER_VALUE_DISCOUNT_APPLIED": 
        return "Discount couldn't be used with your existing discounts.";
    default: 
        return "Unable to find a valid discount matching the code entered";
    }
};

export const CartContext = createContext(null);

const useCart = () => {
    const [cart, setCart] = useState(initialState);

    // Should be used on any function that changes the Checkout Object
    const updateCart = async (checkout) => {
        if (!Object.keys(checkout || {}).length) return;
        const lineItems = checkout?.lineItems;
        
        // Cart State
        const createdAt = new Date(checkout?.createdAt);
        const updatedAt = new Date(checkout?.updatedAt);
        const items = lineItems?.edges?.length ? lineItems.edges : [];

        const calculatedPrice = items.reduce((acc, item) => {
            const itemTotalPrice = item?.node?.customAttributes.length > 0 && item?.node?.customAttributes.find((attr) => attr.key === "__basePrice") ? +item.node.customAttributes.filter((attr) => attr.key === "__basePrice")[0].value * item.node.quantity : +item.node.variant.price.amount * item.node.quantity;
            return acc + itemTotalPrice;
        }, 0);

        const newCart = await getStableCheckout({ items });

        const appliedDiscount = await applyProxyDiscountV2({ ...newCart, discountCode: checkout.discountApplications.edges.length > 0 ? checkout.discountApplications.edges[0].node.code : "" });
        
        const subtotalPrice = { currencyCode: checkout?.lineItemsSubtotalPrice.currencyCode, amount: calculatedPrice };
        const hasDiscount = Boolean(checkout.discountApplications.edges.length);  
        
        const checkoutUrl = checkout?.webUrl;
        const totalPrice = { currencyCode: checkout?.totalPrice.currencyCode, amount: appliedDiscount?.total_discounts !== 0 ? calculatedPrice - +appliedDiscount.total_discounts : calculatedPrice };
        const discountCode = checkout?.discountApplications.edges.length > 0 ? checkout.discountApplications.edges[0].node.code : "";
        const discountPrice = { currencyCode: subtotalPrice.currencyCode, amount: appliedDiscount?.total_discounts !== 0 ? +appliedDiscount.total_discounts : subtotalPrice };

        // console.log("track cart", {
        //     items,
        //     checkoutUrl,
        //     discountCode,
        //     totalPrice,
        //     subtotalPrice,
        //     discountPrice,
        //     createdAt,
        //     updatedAt,
        //     hasDiscount,
        //     error: false,
        //     isLoading: false,
        // });

        setCart(() => ({
            items,
            checkoutUrl,
            discountCode,
            totalPrice,
            subtotalPrice,
            discountPrice,
            createdAt,
            updatedAt,
            hasDiscount,
            error: false,
            isLoading: false,
        }));

        if (SYNC_WITH_OLD_SITE) {
            await createOrUpdateCartCookie({
                items,
                error: false,
                checkoutUrl,
                discountCode,
                totalPrice,
                subtotalPrice,
                discountPrice,
                createdAt,
                updatedAt,
            });
        }
    };

    // Is called only when initially landing on the page
    const fetchCart = async () => {
        try {
            if (!SYNC_WITH_OLD_SITE) {
                let checkoutId = await getOrCreateCheckoutId();
                let checkout = await getCheckout(checkoutId);

                // Create new checkout if the old checkout is completed or doesn't exist anymore
                if (checkout?.data?.node === null || checkout?.data?.node?.completedAt !== null) {
                    checkoutId = await getOrCreateCheckoutId(false, []);
                    checkout = await getCheckout(checkoutId);
                }

                const lastUpdatedAt = new Date(checkout.data.node.updatedAt).getTime();

                // Should only be called if the cart has been updated or the first time landing in the website
                if (!cart.updatedAt || !(cart.updatedAt.getTime() === lastUpdatedAt)) await updateCart(checkout.data.node);
                return;
            }
            
            const cookieBase = getCartSessionFromCookie();

            const base = { 
                cartItemsLength: cookieBase === null ? 0 : cookieBase?.checkout?.checkout.items.length,
                fromHeadless: cookieBase === null ? true : cookieBase?.checkout.fromHeadless, 
                items: cookieBase === null ? [] : cookieBase?.checkout?.checkout.items,
            };

            if (base.cartItemsLength) {
                if (base.fromHeadless) {
                    const checkoutId = await getOrCreateCheckoutId();
                    const checkout = await getCheckout(checkoutId);
                    const lastUpdatedAt = new Date(checkout.data.node.updatedAt).getTime();
                
                    // Should only be called if the cart has been updated or the first time landing in the website
                    if (!cart.updatedAt || !(cart.updatedAt.getTime() === lastUpdatedAt)) await updateCart(checkout.data.node);
                } else {
                    const checkoutId = await getOrCreateCheckoutId(false, base.items);
                    const checkout = await getCheckout(checkoutId);
                    const lastUpdatedAt = new Date(checkout.data.node.updatedAt).getTime();
                    if (!cart.updatedAt || !(cart.updatedAt.getTime() === lastUpdatedAt)) await updateCart(checkout.data.node);
                }
            } else {
                // Creates a new checkout with a new checkoutId if one doesn't already exist
                const checkoutId = await getOrCreateCheckoutId(false, []);
                const checkout = await getCheckout(checkoutId);
                const lastUpdatedAt = new Date(checkout.data.node.updatedAt).getTime();
                
                // Should only be called if the cart has been updated or the first time landing in the website
                if (!cart.updatedAt || !(cart.updatedAt.getTime() === lastUpdatedAt)) await updateCart(checkout.data.node);
            }
        } catch (err) {
            setCart(async (prevState) => ({ ...prevState, error: err }));
        }
    };

    const addCartItem = async (items) => {
        try {
            const checkoutId = await getOrCreateCheckoutId();
            const lineItems = items.reduce((arr, item) => [...arr, {
                variantId: item.variantId,
                quantity: item?.quantity,
                customAttributes: item?.customAttributes ? item.customAttributes : [],
            }], []);
            const updatedCheckout = await addLineItem(checkoutId, lineItems);

            await updateCart(updatedCheckout.data.checkoutLineItemsAdd.checkout, "some");
        } catch (err) {
            setCart(async (prevState) => ({ ...prevState, error: err }));
        }
    };

    const updateCartItem = async (variantId, quantity) => {
        try {
            const checkoutId = await getOrCreateCheckoutId();
            const lineItem = [{
            // eslint-disable-next-line id-length
                id: variantId,
                quantity,
            }];

            const updatedCheckout = await updateLineItem(checkoutId, lineItem);

            await updateCart(updatedCheckout.data.checkoutLineItemsUpdate.checkout);
        } catch (err) {
            setCart(async (prevState) => ({ ...prevState, error: err }));
        }
    };

    const applyDiscount = async (discountCode, onSuccessfullyAppliedCode) => {
        try {
            const checkoutId = await getOrCreateCheckoutId();
            const updatedCheckout = await applyDiscountCode(checkoutId, discountCode);
            const discountMessage = Boolean(updatedCheckout.data.checkoutDiscountCodeApplyV2.checkout?.discountApplications?.edges?.length);

            if (!discountMessage || updatedCheckout.data.checkoutDiscountCodeApplyV2?.checkoutUserErrors?.length > 0 || updatedCheckout.data.checkoutDiscountCodeApplyV2?.checkout?.discountApplications?.edges?.length === 0) {
                setCart((prev) => ({ ...prev, hasDiscount: getAppliedDiscountCodeErrors(updatedCheckout.data.checkoutDiscountCodeApplyV2?.checkoutUserErrors[0]?.code) }));
            } else {
                await updateCart(updatedCheckout.data.checkoutDiscountCodeApplyV2.checkout);

                if (onSuccessfullyAppliedCode) onSuccessfullyAppliedCode(updatedCheckout.data.checkoutDiscountCodeApplyV2);
            }
        } catch (err) {
            setCart(async (prevState) => ({ ...prevState, error: err }));
        }
    };

    const removeDiscount = async () => {
        try {
            const checkoutId = await getOrCreateCheckoutId();
            const updatedCheckout = await removeDiscountCode(checkoutId);
            await updateCart(updatedCheckout.data.checkoutDiscountCodeRemove.checkout); 
        } catch (err) {
            setCart(async (prevState) => ({ ...prevState, error: err }));
        }
    };
    
    const checkoutRedirect = async (inputRefs) => {
        const hasPatioProducts = cart.items.some((item) => item.node.customAttributes.some((attr) => attr.key === "_customProduct"));
        const hasWarrantyProducts = checkWarrantyProducts(cart.items);

        if (hasPatioProducts || hasWarrantyProducts) {
            const checkoutObject = await getStableCheckout(cart, inputRefs);
            const proxyCheckout = await axios.post(checkoutProxyURL, checkoutObject)
                .catch((error) => {
                // eslint-disable-next-line no-console
                    console.log(error);
                });
            return proxyCheckout.data;
        } 

        const url = new URL(cart.checkoutUrl);
        url.hostname = new URL(process.env.GATSBY_STORE_URL).hostname;

        return { checkout_link: url.toString() };
    };

    return {
        cart, updateCartItem, addCartItem, fetchCart, applyDiscount, removeDiscount, checkoutRedirect,
    };
};

const CartProvider = ({ children }) => {
    const cart = useCart();
    return (
        <CartContext.Provider value={cart}>
            {children}
        </CartContext.Provider>
    );
};

export default CartProvider;
