import React, { createContext, useState } from "react";

const initialState = {};

export const ProductFinderContext = createContext(null);

const useProductFinder = () => {
    const [productFinder, setProductFinder] = useState(initialState);

    return { productFinder, setProductFinder };
};

const ProductFinderProvider = ({ children }) => {
    const productFinder = useProductFinder();
    return (
        <ProductFinderContext.Provider value={productFinder}>
            {children}
        </ProductFinderContext.Provider>
    );
};

export default ProductFinderProvider;
