const variantUSP = [
    {
        key: "Waterproof",
        image: "https://cdn.shopify.com/s/files/1/0168/9760/5686/files/Waterproof-Icon.png",
        text: {
            supreme: "Yes",
            elite: "Yes",
            proguard: "Yes",
        },
    },
    {
        key: "UV Resistant",
        image: "https://cdn.shopify.com/s/files/1/0168/9760/5686/files/UV-Resistant-Icon.svg",
        text: {
            supreme: "High",
            elite: "Medium",
            proguard: "High",
        },
    },
    {
        key: "Warranty",
        image: "https://cdn.shopify.com/s/files/1/0168/9760/5686/files/Warranty-Icon.png",
        text: {
            supreme: "10 Years",
            elite: "5 Years",
            proguard: "3 Years",
        },
    },
    {
        key: "Weight",
        image: "https://cdn.shopify.com/s/files/1/0168/9760/5686/files/Weight-Icon.png",
        text: {
            supreme: "18 Oz",
            elite: "8 Oz",
            proguard: "12 Oz",
        },
    },
];

const variantText = {
    supreme: [
        { key: "material", value: "SEAL-TEC Technology, 1000 Denier One Side PVC Coated Fabric Diamond Finish" },
        { key: "feature", value: "High Durability, Heavy Duty, Tear & Abrasion Resistant" },
        { key: "ideal for", value: "Extreme Weather" },
    ],
    elite: [
        { key: "material", value: "600 Denier Solution Dyed Polyester Fabric" },
        { key: "feature", value: "Great Durability, Tear & Abrasion Resistant" },
        { key: "ideal for", value: "Moderate Weather" },
    ],
    proguard: [
        { key: "material", value: "1000 Denier Both Side PVC Coated Fabric" },
        { key: "feature", value: "Great Durability, Tear & Abrasion Resistant" },
        { key: "ideal for", value: "Moderate Weather" },
    ],
};

const personalizationOptions = {
    text: "Print Text on Cover | Tarp",
    logo: "Print LOGO on Cover | Tarp",
};

const cartPatioProperties = [
    {
        key: "personalization",
        value: "Personalize Type",
    },
    {
        key: "logo",
        value: "Logo",
    },
    {
        key: "personalizationSide",
        value: "Print Sides",
    },
    {
        key: "personalizationColor",
        value: "Color",
    },
    {
        key: "personalizationFont",
        value: "Font",
    },
];

const patioData = {
    variantUSP, variantText, personalizationOptions, cartPatioProperties,
};

export default patioData;
