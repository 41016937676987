import React, { createContext, useState } from "react";
import {
    localStorageKey, sessionStorageKey, authErrors, cleanAddressId, checkoutKey,
} from "utils/constants";
import navigate from "@helpers/link-navigate";
import {
    createCustomer, createCustomerAccessToken, setCustomerAccessToken, createPersonalAddress, 
    deletePersonalAddress, getCustomer, updatePersonalAddress, updateDefaultAddress,
} from "services/storefront/account";
import { notifySuccess, notifyError } from "helpers/toast";
import { trackLogIn, trackSignUp } from "analytics";

const initialState = {
    token: {
        accessToken: null,
        expiresAt: null,
    }, 
    user: {
        displayName: "",
        firstName: "",
        lastName: "",
        email: "",
        // eslint-disable-next-line id-length
        id: "",
        addresses: {},
        orders: [],
        defaultAddress: {},
    },
    authenticated: false,
    authenticating: false,
    error: null,
};

export const AccountContext = createContext(null);

const useAccount = () => {
    const [account, setAccount] = useState(initialState);

    const getCustomerProfile = async () => {
        setAccount((prevState) => ({ ...prevState, authenticating: true }));
        const customer = await getCustomer();

        if (!customer) return null;

        const addresses = customer.addresses.edges
            .map((address) => ({ [cleanAddressId(address.node.id)]: address.node }))
            .reduce((accumulator, currentValue) => ({ ...accumulator, ...currentValue }), {});   

        trackLogIn(customer);

        return setAccount((prevState) => ({ ...prevState, user: { ...customer, addresses } }));
    };

    const logIn = async (form) => {
        const customer = await createCustomerAccessToken(form);
    
        if (customer?.data?.customerAccessTokenCreate?.customerUserErrors?.length > 0) {
            const unknownCustomer = customer.data?.customerAccessTokenCreate?.customerUserErrors?.find((error) => error.code === "UNIDENTIFIED_CUSTOMER");
            if (unknownCustomer) {
                return setAccount((prevState) => ({
                    ...prevState,
                    error: authErrors[unknownCustomer.code],
                    authenticated: false,
                    authenticating: true, 
                }));
            }
        }
    
        setCustomerAccessToken(customer.data.customerAccessTokenCreate.customerAccessToken);
    
        await getCustomerProfile();
        navigate("/account");
    
        return setAccount((prevState) => ({ ...prevState, authenticated: true }));
    };

    const signUp = async (form) => {
        const customer = await createCustomer(form);
    
        if (customer.data?.customerCreate?.customerUserErrors) {
            const exists = customer.data.customerCreate?.customerUserErrors.find((error) => error.code === "TAKEN");
            const activationNeeded = customer.data.customerCreate?.customerUserErrors.find((error) => error.code === "CUSTOMER_DISABLED"); 
    
            if (exists) return setAccount((prevState) => ({ ...prevState, error: authErrors[exists.code] }));
            if (activationNeeded) return setAccount((prevState) => ({ ...prevState, error: authErrors[activationNeeded.code] }));
        } else if (customer.errors) {
            return setAccount((prevState) => ({ ...prevState, error: customer.errors.map((err) => err.message).join("") }));
        }
    
        const customerAccessToken = await createCustomerAccessToken({
            email: form.email,
            password: form.password,
        });

        trackSignUp(customer.data.customerCreate.customer);
    
        setCustomerAccessToken(customerAccessToken.data.customerAccessTokenCreate?.customerAccessToken);
        navigate("/account/login");
    
        return setAccount((prevState) => ({ ...prevState, token: customerAccessToken }));
    };

    const logOut = () => {
        window.localStorage.removeItem(localStorageKey);
        window.sessionStorage.removeItem(sessionStorageKey);
        window.localStorage.removeItem(checkoutKey);
        
        setAccount((prevState) => ({
            ...prevState, authenticated: false, authenticating: true, user: {}, 
        }));
    };

    const loginUserFromSession = () => {
        setAccount((prevState) => ({ ...prevState, authenticating: true }));

        if (window.sessionStorage.getItem(sessionStorageKey)) {
            setAccount((prevState) => ({
                ...prevState, user: JSON.parse(window.sessionStorage.getItem(sessionStorageKey)), authenticated: true, 
            }));
        }
        setAccount((prevState) => ({ ...prevState, authenticating: false }));
    };

    const createAddress = async (form) => {
        const { defaultAddress, ...address } = form;
        const newAddress = await createPersonalAddress(address);
        const addressesLength = Object.keys(account?.user?.addresses || {}).length;

        if (newAddress?.data.customerAddressCreate?.customerUserErrors?.length > 0) {
            notifyError("Address could not be created");
            return;
        }        
        
        // Create Address in Context
        setAccount((prevState) => ({
            ...prevState,
            user: {
                ...prevState.user,
                addresses: {
                    [cleanAddressId(newAddress.data.customerAddressCreate.customerAddress.id)]: newAddress.data.customerAddressCreate.customerAddress,
                    ...prevState.user.addresses,
                },
            },
        }));

        // If there are no other existing Addresses, this Adress will be the default Address
        // In the background, the Shopify API will create this address and make it Default immediately
        // Therefore, we don't need to call updateDefaultAddress() here
        if (addressesLength === 0) {
            setAccount((prevState) => ({
                ...prevState,
                user: {
                    ...prevState.user,
                    defaultAddress: newAddress.data.customerAddressCreate.customerAddress, 
                }, 
            }));
        }

        // If this address has been chosen to be the new default address, and there are other existing addresses, make it the default one
        if (defaultAddress && addressesLength !== 0) {
            await updateDefaultAddress(newAddress.data.customerAddressCreate.customerAddress.id);

            setAccount((prevState) => ({
                ...prevState,
                user: {
                    ...prevState.user,
                    defaultAddress: newAddress.data.customerAddressCreate.customerAddress, 
                }, 
            }));
        }

        notifySuccess("Address created successfully");
    };

    const updateAddress = async (form) => {
        const { id: addressId, defaultAddress, ...address } = form;
        const updatedAddress = await updatePersonalAddress(addressId, address);

        // Update Address
        if (updatedAddress?.data.customerAddressUpdate?.customerUserErrors?.length < 1) {
            setAccount((prevState) => ({
                ...prevState,
                user: {
                    ...prevState.user,
                    addresses: {
                        ...prevState.user.addresses,
                        [cleanAddressId(updatedAddress.data.customerAddressUpdate.customerAddress.id)]: { ...updatedAddress.data.customerAddressUpdate.customerAddress },
                    },
                },
            }));

            // Update this Address to be the default one
            if (defaultAddress) {
                await updateDefaultAddress(addressId);
                setAccount((prevState) => ({
                    ...prevState,
                    user: {
                        ...prevState.user,
                        defaultAddress: { ...updatedAddress.data.customerAddressUpdate.customerAddress },
                    }, 
                }));
            }
            notifySuccess("Address updated successfully");
        } else {
            notifyError("Address could not be updated");
        }
    };

    const shouldChangeDefaultAddress = (addressId) => {
        // Check if there are no more existing addresses after the last deleted address
        if (Object.values(account.user.addresses).length !== 1) {
            const isDefaultAddress = cleanAddressId(addressId) === cleanAddressId(account.user.defaultAddress.id);

            if (isDefaultAddress) {
                const deletedAddressIndex = Object.keys(account.user.addresses).indexOf(cleanAddressId(addressId));
                
                let nextAddressToBeDefault;
                if (Object.values(account.user.addresses)[deletedAddressIndex - 1]) {
                    nextAddressToBeDefault = Object.values(account.user.addresses)[deletedAddressIndex - 1];                
                } else {
                    nextAddressToBeDefault = Object.values(account.user.addresses)[deletedAddressIndex + 1];
                }
                setAccount((prevState) => ({ ...prevState, user: { ...prevState.user, defaultAddress: nextAddressToBeDefault } }));
            }
        }
    };

    const deleteAddress = async (addressId) => {
        const deletedAddress = await deletePersonalAddress(addressId);
        // Remove deleted address from addresses object
        const { [cleanAddressId(addressId)]: deleted, ...newAddresses } = account.user.addresses;
    
        if (deletedAddress?.data?.customerAddressDelete?.customerUserErrors?.length < 1) {
            setAccount((prevState) => ({ ...prevState, user: { ...prevState.user, addresses: newAddresses } }));

            // See if deleted address is the default address, in which case the next address in line will be the Default
            shouldChangeDefaultAddress(addressId);
            notifySuccess("Address deleted successfully");
        } else {
            notifyError("Address could not be deleted");
        }
    };

    const setError = (error) => setAccount((prevState) => ({ ...prevState, error }));
    
    return {
        account, setAccount, getCustomerProfile, createAddress, updateAddress, deleteAddress, logIn, signUp, logOut, loginUserFromSession, setError,
    };
};

const AccountProvider = ({ children }) => {
    const account = useAccount();
    return (
        <AccountContext.Provider value={account}>
            {children}
        </AccountContext.Provider>
    );
};

export default AccountProvider;
